import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';

function AuthenticationPage({ setIsAuthenticated, setUserRole }) {
  const [password, setPassword] = useState('');
  const [selectedRole, setSelectedRole] = useState('admin'); // Default to guest
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    setLoading(true);
    setErrorMessage('');
    
    setTimeout(() => {
      if (selectedRole === 'admin' && password === 'rootsr') {
        setIsAuthenticated(true);
        setUserRole('admin');
        navigate('/todolist');
      } else if (selectedRole === 'guest' && password === 'shubhbham') {
        setIsAuthenticated(true);
        setUserRole('guest');
        navigate('/todolist');
      } else {
        setErrorMessage('Incorrect password. Please try again.');
      }
      setLoading(false);
    }, 1000); // Simulate loading
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-950 to-blue-900 text-gray-100 flex flex-col justify-center items-center">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-sm">
        <h2 className="text-2xl font-bold text-center text-amber-300 mb-6">{`Magic pro`}</h2>
        <div className="mb-4">
          <label className="block text-gray-300 mb-2">Select Role:</label>
          <select
            className="w-full p-3 bg-gray-900 text-gray-100 rounded-lg focus:ring-2 focus:ring-blue-500"
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
          >
            <option value="admin">Admin</option>
            <option value="guest">Guest</option>
          </select>
        </div>
        <div className="mb-4">
          <input
            type="password"
            placeholder="Enter password"
            className="w-full p-3 bg-gray-900 text-gray-100 rounded-lg border border-gray-700 focus:ring-2 focus:ring-blue-500"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {errorMessage && <div className="bg-red-500 text-white p-2 rounded-lg text-center mb-4">{errorMessage}</div>}
        <button
          className="w-full p-3 bg-blue-600 text-white rounded-lg shadow-lg hover:bg-blue-700 flex justify-center items-center"
          onClick={handleLogin}
          disabled={loading}
        >
          {loading ? <FaSpinner className="animate-spin" /> : 'Login'}
        </button>
      </div>
    </div>
  );
}

export default AuthenticationPage;
