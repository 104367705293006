import axios from 'axios';

// Create an instance of Axios
const api = axios.create({
  baseURL: 'https://todo-magic-pro.onrender.com/api',
});

// Adding a response interceptor
api.interceptors.response.use(
  (response) => {
    switch (response.status) {
      case 200:
        console.log('Request succeeded with response:', response.data);
        break;
      case 204:
        console.log('No content available');
        break;
      default:
        console.log('Request succeeded with status:', response.status);
    }
    return response;
  },
  (error) => {
    // Handle error responses
    if (error.response) {
      // Server responded with a status other than 2xx
      switch (error.response.status) {
        case 400:
          console.error('Bad Request:', error.response.data);
          break;
        case 401:
          console.error('Unauthorized:', error.response.data);
          break;
        case 403:
          console.error('Forbidden:', error.response.data);
          break;
        case 404:
          console.error('Not Found:', error.response.data);
          break;
        case 500:
          console.error('Internal Server Error:', error.response.data);
          break;
        default:
          console.error('Unhandled Error:', error.response.data);
      }
    } else if (error.request) {
      // No response was received from the server
      console.error('No response received:', error.request);
    } else {
      // Something went wrong in setting up the request
      console.error('Request error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default api;
