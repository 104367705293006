import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../api";
import { FaEdit, FaTrash, FaEye } from "react-icons/fa";
import ReactQuill from "react-quill";
import Loader from "./Loader";
import "react-quill/dist/quill.snow.css";

function TodoList() {
  const [todos, setTodos] = useState([]);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [deadline, setDeadline] = useState("");
  const [completed, setCompleted] = useState(false);
  const [editingTodo, setEditingTodo] = useState(null);
  const [loading, setLoading] = useState(true); // Set to true initially to show loader on first visit
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchTodos();
  }, []);

  const fetchTodos = async () => {
    try {
      const response = await api.get("/todos");
      setTodos(response.data || []);
    } catch (error) {
      console.error("Error fetching todos:", error.message);
    } finally {
      setLoading(false); // Hide loader after fetching todos
    }
  };

  const createOrUpdateTodo = async () => {
    if (!isFormValid()) return;

    const todoData = {
      title,
      body,
      deadline: deadline ? new Date(deadline).toISOString() : undefined,
      completed,
    };

    try {
      if (editingTodo) {
        await api.patch(`/todos/${editingTodo}`, todoData);
      } else {
        await api.post("/todos", todoData);
      }
      resetForm();
      fetchTodos();
    } catch (error) {
      console.error(
        "Error creating/updating todo:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const resetForm = () => {
    setTitle("");
    setBody("");
    setDeadline("");
    setCompleted(false);
    setEditingTodo(null);
  };

  const deleteTodo = async (id) => {
    try {
      await api.delete(`/todos/${id}`);
      fetchTodos();
    } catch (error) {
      console.error(
        "Error deleting todo:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const isFormValid = () => {
    return title && body && deadline;
  };

  const getDeadlineColor = (deadline) => {
    if (!deadline) return "";
    const now = new Date();
    const deadlineDate = new Date(deadline);
    const timeLeft = deadlineDate - now;

    if (timeLeft <= 0) {
      return "text-red-500";
    } else if (timeLeft <= 1000 * 60 * 60 * 24 * 2) {
      return "text-red-500";
    } else if (timeLeft <= 1000 * 60 * 60 * 24 * 5) {
      return "text-yellow-500";
    } else if (timeLeft <= 1000 * 60 * 60 * 24 * 7) {
      return "text-green-300";
    } else {
      return "text-green-600";
    }
  };

  const filteredTodos = todos.filter((todo) =>
    todo.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const getFirstThreeLines = (text) => {
    const lines = text.split("\n");
    return lines.slice(0, 3).join(" ");
  };

  return (
    <>
      <div className="bg-gray-900 text-gray-100 min-h-screen flex flex-col transition-all duration-500">
        <div className="container mx-auto p-4">
          <h1 className="text-2xl font-bold mb-6 mt-2 text-center transition-colors text-amber-300 duration-300">{`Magic pro`}</h1>

          <div className="bg-gray-800 p-6 rounded-lg shadow-lg mb-6 transition-transform duration-300">
            <input
              type="text"
              placeholder="Search by title..."
              className="border border-gray-700 p-4 mb-4 w-full rounded-lg bg-gray-900 text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            {searchTerm && (
              <ul className="bg-gray-800 text-white p-2 rounded-lg shadow-lg mb-4">
                {filteredTodos.map((todo) => (
                  <li
                    key={todo._id}
                    className="hover:bg-gray-700 p-2 rounded-lg"
                  >
                    <Link to={`/todo/${todo._id}`} className="block">
                      {todo.title}
                    </Link>
                  </li>
                ))}
              </ul>
            )}

            <input
              type="text"
              placeholder="Title"
              className="border border-gray-700 p-4 mb-4 w-full rounded-lg bg-gray-900 text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <ReactQuill
              value={body}
              onChange={setBody}
              className="bg-gray-900 text-gray-100 border border-gray-700 mb-4"
              theme="snow"
            />
            <input
              type="datetime-local"
              className="border border-gray-700 p-4 mb-4 w-full rounded-lg bg-gray-900 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
            />
            <select
              className="border border-gray-700 p-4 mb-14 w-full rounded-lg bg-gray-900 text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-300"
              value={completed ? "Done" : "Pending"}
              onChange={(e) => setCompleted(e.target.value === "Done")}
            >
              <option value="Pending">Pending</option>
              <option value="Done">Done</option>
            </select>
            <button
              className={`bg-blue-600 text-white p-3 rounded-lg shadow hover:bg-blue-700 transition relative ${
                !isFormValid() && "opacity-50 cursor-not-allowed"
              } transform transition-transform duration-300`}
              onClick={createOrUpdateTodo}
              disabled={!isFormValid()}
            >
              {editingTodo ? "Update" : "Create"}
            </button>
          </div>
          <div>
            {loading ? (
              <Loader /> // Render loader only on first load
            ) : (
              <div>
                {todos.map((todo) => (
                  <div
                    key={todo._id}
                    className={`bg-gray-800 p-6 mb-4 rounded-lg shadow-lg flex justify-between items-center transition-all duration-300 ${
                      todo.completed ? "opacity-50 blur-sm" : ""
                    }`}
                  >
                    <div>
                      <h2 className="text-2xl font-semibold mb-2">
                        {todo.title}
                      </h2>
                      <p className="mb-2">
                        {getFirstThreeLines(stripHtmlTags(todo.body))}
                      </p>
                      {todo.deadline && (
                        <p
                          className={`mb-2 ${getDeadlineColor(todo.deadline)}`}
                        >
                          Deadline: {new Date(todo.deadline).toLocaleString()}
                        </p>
                      )}
                      <p>Status: {todo.completed ? "Done" : "Pending"}</p>
                    </div>
                    <div className="flex flex-col md:flex-row ml-2">
                      <Link to={`/todo/${todo._id}`}>
                        <button className="bg-blue-500 text-white p-2 rounded-lg shadow hover:bg-blue-600 mb-2 md:mb-0 md:mr-2 ml-2 transform hover:scale-105 transition-transform duration-300">
                          <FaEye />
                        </button>
                      </Link>
                      <button
                        className="bg-yellow-500 text-white p-2 rounded-lg shadow hover:bg-yellow-600 mb-2 md:mb-0 md:mr-2 transform hover:scale-105 transition-transform duration-300"
                        onClick={() => {
                          setTitle(todo.title);
                          setBody(todo.body);
                          setDeadline(
                            todo.deadline
                              ? new Date(todo.deadline)
                                  .toISOString()
                                  .slice(0, 16)
                              : ""
                          );
                          setCompleted(todo.completed);
                          setEditingTodo(todo._id);
                        }}
                      >
                        <FaEdit />
                      </button>
                      <button
                        className="bg-red-500 text-white p-2 rounded-lg shadow hover:bg-red-600 transform hover:scale-105 transition-transform duration-300"
                        onClick={() => deleteTodo(todo._id)}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default TodoList;
