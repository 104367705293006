import React, { useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import TodoList from './Components/TodoList';
import TodoDetail from './Components/TodoDetail';
import AuthenticationPage from './Components/AuthenticationPage';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(''); // 'admin' or 'guest'
  
  return (
    <Router>
      <Routes>
        <Route 
          path="/" 
          element={
            isAuthenticated && userRole === 'admin' ? (
              <TodoList />
            ) : (
              <AuthenticationPage setIsAuthenticated={setIsAuthenticated} setUserRole={setUserRole} />
            )
          } 
        />
        <Route path="/todo/:id" element={<TodoDetail />} />
        <Route 
          path="/todolist" 
          element={
            isAuthenticated ? (
              <TodoList />
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
