import React from 'react';

const Loader = () => {
  return (
    <div className="animate-pulse space-y-4">
  {Array.from({ length: 3 }).map((_, index) => (
    <div key={index} className="border border-gray-500 shadow rounded-md p-4 w-full">
      <div className="flex space-x-4">
        <div className="rounded-full bg-gray-700 h-10 w-10"></div>
        <div className="flex-1 space-y-6 py-1">
          <div className="h-4 bg-gray-700 rounded w-3/4"></div>
          <div className="space-y-3">
            <div className="h-4 bg-gray-700 rounded"></div>
            <div className="h-4 bg-gray-700 rounded w-1/2"></div>
          </div>
        </div>
      </div>
    </div>
  ))}
</div>

  );
};

export default Loader;
